import {
  LOGIN,
  LOGOUT,
  UPDATE_AUTH_USER,
  REGISTER,
  SET_KEEP_ME_LOGGED_IN,
  EDIT_WATCHLIST_ADD,
  EDIT_WATCHLIST_REMOVE,
  RESTORE,
  OIDC_CALLBACK,
  OIDC_CONFIGURATIONS,
  GET_OIDC_CONFIGURATION,
  SET_IS_LINKED_TO_OIDC_USER,
  GET_LINKED_OIDC_CONFIGURATIONS_FOR_USER
} from './constants'
import reducers from './reducers'

const defaultState = {
  user: undefined,
  status: undefined,
  error: undefined,
  action: undefined,
  keepMeLoggedIn: false,
  restoreStatus: undefined
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGIN:
      return reducers.login(state, action)
    case REGISTER:
      return reducers.register(state, action)
    case LOGOUT:
      return reducers.logout(state, action)
    case UPDATE_AUTH_USER:
      return reducers.updateUser(state, action)
    case SET_KEEP_ME_LOGGED_IN:
      return reducers.setKeepMeLoggedIn(state, action)
    case EDIT_WATCHLIST_ADD:
    case EDIT_WATCHLIST_REMOVE:
      return reducers.editWatchList(state, action)
    case RESTORE:
      return reducers.restore(state, action)
    case OIDC_CALLBACK:
      return reducers.OIDCCallback(state, action)
    case OIDC_CONFIGURATIONS:
      return reducers.getOIDCConfigurations(state, action)
    case GET_OIDC_CONFIGURATION:
      return reducers.getOIDCConfiguration(state, action)
    case SET_IS_LINKED_TO_OIDC_USER:
      return reducers.setIsLinkedToOIDCUser(state, action)
    case GET_LINKED_OIDC_CONFIGURATIONS_FOR_USER:
      return reducers.getLinkedOIDCConfigurationsForUser(state, action)
    default:
      return state
  }
}
